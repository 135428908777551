import React, { useState } from "react";
import Logo from "../images/zetatransparent.png";
import Logo2 from "../images/zetatranspa.png";
import { Link } from "react-router-dom";
import { CgMenuGridO } from "react-icons/cg";
import { AiOutlineCalculator } from "react-icons/ai";
import { AiFillQuestionCircle } from "react-icons/ai";
import { SiUblockorigin } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
//import { IoMdCloseCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import "@rainbow-me/rainbowkit/styles.css";
import { ConnectButton, darkTheme } from "@rainbow-me/rainbowkit";
import { ethers } from "ethers";
import { FarmAddress, FARM_ABI, ICO_Price } from "../contracts/launchpad";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
  bscTestnet,
} from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { Navbar } from "../components/Navbar";
import { parseEther } from "@ethersproject/units";
import { getAddress } from "@zetachain/protocol-contracts"; // Import your contract address resolver
import { prepareData, trackCCTX } from "@zetachain/toolkit/helpers"; // Import any other necessary helpers
import EventEmitter from "eventemitter3";
import { useEffect } from "react";
import ZedaFarm from "../components/ZedaFarm";

const Blast = {
  id: 168587773,
  name: 'Blast',
  network: 'Blast',
  iconUrl: 'https://example.com/icon.svg',
  iconBackground: '#fff',
  nativeCurrency: {
      decimals: 18,
      name: 'ETH',
      symbol: 'ETH',
  },
  rpcUrls: {
      public: { http: ['https://sepolia.blast.io'] },
      default: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
      default: { name: 'BlastScan', url: 'https://testnet.blastscan.io' },
      etherscan: { name: 'BlastScan', url: 'https://testnet.blastscan.io' },
  },
  contracts: {
      multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 11_907_934,
      },
  },
  testnet: false,
};

const { chains, publicClient } = configureChains(
  [Blast],
  [
    alchemyProvider({ apiKey: "qVf7pnl78NlUKvDQ4P2mFYmfigvA4D5h" }),
    publicProvider(),
  ]
);
const { connectors } = getDefaultWallets({
  appName: "zedafarm",
  projectId: "579e29718b5b76adb4b3aade0ce1648b",
  chains,
});
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const Farm = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isOpenSlider, setIsOpenSlider] = useState(true);
  const [userReward, setUserReward] = useState(0);
  const [farmRewardRate, setFarmRewardRate] = useState(0);
  const [BNBBalance, setBNBBalance] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [TVL, setTVL] = useState(0);

  useEffect(() => {
    async function getBNBPrice() {
      try {
        // console.log("getting bnb price");
        // Define the API endpoint to fetch BNB price (you may need to use a different API)
        const apiUrl =
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";

        // Send an HTTP GET request to the API
        const response = await fetch(apiUrl);

        // Check if the request was successful (status code 200)
        if (response.status === 200) {
          // Parse the response as JSON
          const data = await response.json();

          // Extract the BNB price from the response data
          const bnbPriceUSD = data.binancecoin.usd;
          //   console.log(bnbPriceUSD);
          if (bnbPriceUSD) {
            //console.log("setting bnb price");
            setBnbPrice(bnbPriceUSD);
          }
          return bnbPriceUSD;
        } else {
          console.log("Failed to fetch BNB price");
        }
      } catch (error) {
        console.error("Error fetching BNB price:", error.message);
        // You can handle or propagate the error as needed
      }
    }

    async function getAvailableReward(address) {
      try {
        // ZetaChain Testnet URL
        const zetaChainProviderUrl =
          "https://zetachain-athens-evm.blockpi.network/v1/rpc/public";
        // Private key of the user (replace with your actual private key)
        const privateKey =
          "d97b2fab8d33da2bfe6e53cd4341f4dc2fb8275ae9667db5c37a11051302c818";
        // Create a new provider using the ZetaChain Testnet URL
        const zetaChainProvider = new ethers.providers.JsonRpcProvider(
          zetaChainProviderUrl
        );
        // console.log({ zetaChainProvider });
        // Create a signer with the private key
        const wallet = new ethers.Wallet(privateKey, zetaChainProvider);
        // console.log({ wallet });
        // Contract ABI (replace with your contract's ABI)
        const contractABI = FARM_ABI;
        // Contract address (replace with your contract's address)
        const contractAddress = FarmAddress;
        // console.log({ contractAddress });
        // Instantiate the contract on ZetaChain Testnet
        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          wallet
        );
        // Call the contract function to get available rewards
        const tx = await contract.getAvailableReward(
          "0x3F85DA8A800Ac9e027Ace7f77d18738d5a4dD8f5"
        );
        const availableRewards = ethers.BigNumber.from(tx);
        // Convert wei to ether
        const availableRewardsInEther =
          ethers.utils.formatEther(availableRewards);

        if (availableRewardsInEther) {
          setUserReward(availableRewardsInEther);
        }
        //console.log({ availableRewardsInEther }); // This will print the value in ether

        //Get Eth balance of contract
        const balanceWei = await provider.getBalance(contractAddress);
        const balanceEther = ethers.utils.formatEther(balanceWei);
        //console.log(`Contract balance (in Ether): ${balanceEther}`);

        // //Getting rewardRate
        const rewardRateTx = await contract.rewardRate();
        const rewardRate = ethers.BigNumber.from(rewardRateTx).toString();
        if (rewardRate) {
          setFarmRewardRate(rewardRate);
        }

        //getting ERC2BNB balance
        const balanceOfABI = [
          {
            constant: true,
            inputs: [
              {
                name: "_owner",
                type: "address",
              },
            ],
            name: "balanceOf",
            outputs: [
              {
                name: "balance",
                type: "uint256",
              },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
        ];

        //console.log("getting token balance");
        // ERC-20 token address (e.g., DAI)
        const tokenAddress = "0xd97B1de3619ed2c6BEb3860147E30cA8A7dC9891";
        // Create an instance of the ERC-20 token contract
        const erc20Contract = new ethers.Contract(
          tokenAddress,
          balanceOfABI,
          wallet
        );

        // console.log({ erc20Contract });

        // Call the balanceOf function of the ERC-20 token contract
        const tokenBalanceWei = await erc20Contract.balanceOf(FarmAddress);

        // console.log({ tokenBalanceWei });

        // Get the decimals of the ERC-20 token
        //const decimals = await erc20Contract.balanceOf(FarmAddress);

        // Convert the balance to a readable format (adjusting for decimals)
        const balance = ethers.utils.formatUnits(tokenBalanceWei, 18);

        //console.log(`Token balance in contract: ${balance}`);

        if (balance) {
          const price = await getBNBPrice();

          setBNBBalance(balance);
          const totalLockedBNBValue = balance * price;
          //console.log(`logging value with bal: ${balance} and price ${price}`);
          //    console.log({ totalLockedBNBValue });
          setTVL(totalLockedBNBValue);
        }

        // Return the result
        // return availableRewards;
      } catch (error) {
        console.error("Error:", error);
        // You can handle or propagate the error as needed
      }
    }

    getAvailableReward("0x3F85DA8A800Ac9e027Ace7f77d18738d5a4dD8f5");
  }, []);

  function toggleNav() {
    //console.log("clicked")
    if (isNavOpen) {
      setIsNavOpen(false);
      //console.log(isNavOpen)
    }
    if (!isNavOpen) {
      setIsNavOpen(true);
      //console.log(isNavOpen)
    }
  }
  function toggleSlider() {
    //console.log("clicked")
    if (isOpenSlider) {
      setIsOpenSlider(false);
      //console.log(isOpenSlider)
    }
    if (!isOpenSlider) {
      setIsOpenSlider(true);
      //console.log(isOpenSlider)
    }
  }

  const [amount, setAmount] = useState("");
  const [transactionHash, setTransactionHash] = useState("");

  // Connect to MetaMask
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      console.error("No Ethereum provider found. Install MetaMask.");
    }
  };

  // Initialize an ethers provider using MetaMask
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  // Initialize your signer with the current Metamask account

  let createAddress = async function () {
    //console.log("creating browser address");
    const signer = provider.getSigner();
    const browserSigner = await signer.getAddress();

    //console.log({ createAddressSigner: signer });
    //console.log(browserSigner)
    return browserSigner;
  };

  createAddress();

  let convertToEthers = async function (data) {
    const dataInWei = ethers.BigNumber.from(data);
    const dataInEther = ethers.utils.formatEther(dataInWei);
    return dataInEther;
  };

  //console.log({ethSigner})
  // Define your contract address on the zeta_testnet
  const tsscontractAddress = "0x8531a5aB847ff5B22D855633C25ED1DA3255247e"; // Replace with the actual contract address
  const myContractAddress = "0x65666dcd7E9afCC97f45Cc18C82Ed5ef028Cd932";

  async function trackingCCTX(txHash) {
    const emitter = new EventEmitter();
    emitter
      .on("search-add", () => console.log("searching"))
      .on("succeed", () => console.log("success"))
      .on("fail", () => console.log("failure"));
    try {
      //console.log(`trying to track: ${txHash}`);
      const status = await trackCCTX(txHash, true, emitter);
      await status.wait(4);
      console.log(status);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // Staking
  // async function stakeTokens(amount) {
  //     console.log(`calling stake at ${FarmAddress}`)
  //     try {
  //         // Convert the amount to the correct format (wei in this case)
  //         const value = parseEther(amount);
  //         // Prepare your transaction data
  //         const data = prepareData(myContractAddress, ['uint8'], ['1']); // Replace with your actual contract function
  //         // Create a transaction object
  //         const tx = {
  //             to: tsscontractAddress,
  //             value: value,
  //             data: data,
  //         };
  //         // Send the transaction using MetaMask
  //         const response = await signer.sendTransaction(tx);
  //         // Wait for the transaction to be mined
  //         await response.wait();
  //         // Transaction was successful

  //         console.log(response)
  //         console.log('Stake Transaction hash:', response.hash);

  //         const txHash = response.hash

  //         trackingCCTX(txHash)

  //     } catch (error) {
  //         console.log(`error from unstake is: ${error}`)
  //     }
  // }

  //Unstake
  // async function UnStakeToken(amount) {
  //     console.log(`calling unstake at ${FarmAddress}`)
  //     //console.log(`track cctx is ${trackCCTX}`)
  //     // console.log(`preparedata is ${prepareData}`)

  //     try {
  //         // Convert the amount to the correct format (wei in this case)
  //         const value = parseEther("0");
  //         // Prepare your transaction data
  //         const data = prepareData(myContractAddress, ['uint8'], ['2']); // Replace with your actual contract function
  //         // Create a transaction object
  //         const tx = {
  //             to: tsscontractAddress,
  //             value: value,
  //             data: data,
  //         };
  //         // Send the transaction using MetaMask
  //         const response = await signer.sendTransaction(tx);
  //         // Wait for the transaction to be mined
  //         await response.wait();
  //         // Transaction was successful
  //         console.log('Unstake Transaction hash:', response.hash);

  //         let txHash = response.hash;

  //         trackingCCTX(txHash)

  //     } catch (error) {
  //         console.error('Error:', error);
  //     }
  // }

  //Get available reward

  //clain rewards
  // Define your contract address on ZetaChain

  //const stakerAddress = 'STAKER_ADDRESS'; // Replace with the staker's address

  // Refactored function for claiming rewards
  // async function claimRewards() {

  //     console.log(`Calling claim rewards`);

  //     try {
  //         // Convert the staker's address to the correct format
  //         const staker = "0x3F85DA8A800Ac9e027Ace7f77d18738d5a4dD8f5"

  //         // Create an instance of your contract
  //         const contract = new ethers.Contract(myContractAddress, ['function claimRewards(address staker)'], signer);

  //         // Call the contract function to claim rewards
  //         const tx = await contract.claimRewards(staker);

  //         // Wait for the transaction to be mined
  //         const receipt = await tx.wait();

  //         // Transaction was successful
  //         console.log('Transaction hash:', tx.hash);

  //         const txHash = tx.hash;
  //         trackingCCTX(txHash)
  //         console.log('Receipt:', receipt);
  //     } catch (error) {
  //         console.error('Error:', error);
  //     }
  // }

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        theme={darkTheme({
          accentColor: "#0fa",
          accentColorForeground: "#131010",
        })}
        chains={chains}
      >
        <div className="bg-[#131010] min-h-[100vh] pb-10">
          <header className="bg-black md:px-32 px-4 fixed w-full flex justify-between items-center z-50">
            <Link to="/">
              <div className="flex items-center">
                <img
                  src={Logo}
                  alt=""
                  height={80}
                  width={80}
                  className="relative"
                />
              </div>
            </Link>
            <div className="flex items-center space-x-3">
              <ConnectButton />
              <div className="relative">
                <div
                  className="text-[#0fa] text-3xl font-bold cursor-pointer hover:brightness-75 hover:scale-125 duration-300"
                  onClick={() => {
                    toggleNav();
                  }}
                >
                  <CgMenuGridO />
                </div>
                {isNavOpen && <Navbar />}
              </div>
            </div>
          </header>
          <div className="text-white text-3xl pt-32 mx-20 font-bold">
            <h1 className="text-xl">
              ZEDASWAP FARM{" "}
              <span className="text-[#0fa] underline cursor-pointer">V1</span>
            </h1>
          </div>
          {/* <main className='pt-8'>
                        <section className='bg-[#0fa]  text-[#131010] md:mx-20 mx-6 rounded-sm p-5'>
                            <div className=' upp-sec flex border-4 border-[#131010] p-4 rounded-md justify-between items-center font-semibold mb-6'>
                                <div className='flex items-center space-x-2 font-semibold'>
                                    <div id='image-container' className='flex relative'>
                                        <div className='bg-[#131010] rounded-full'>
                                            <img src={Logo2} alt='' width={40} />
                                        </div>
                                        <div className='bg-[#0fa] border rounded-full absolute left-6'>
                                            <img src={Logo2} alt='' width={40} />
                                        </div>


                                    </div>
                                    <div>
                                        <h1>ZEDA/ZETA</h1>
                                    </div>
                                </div>
                                <div className='md:flex hidden items-center space-x-2'>
                                    <SiUblockorigin />
                                    <h1>Native</h1>
                                </div>
                                <div className='hidden md:block'>
                                    <h1>Earned</h1>
                                    <h1>{userReward ? userReward : "0"}</h1>
                                </div>
                                <div>
                                    <h1>APR</h1>
                                    <div className='flex items-center space-x-1'>
                                        <h1>{farmRewardRate ? (`${farmRewardRate * 650}%`) : "0%"}</h1>
                                        <AiOutlineCalculator />
                                    </div>

                                </div>
                                <div className='hidden md:block'>
                                    <h1>Liquidity</h1>
                                    <div className='flex items-center space-x-1'>
                                        <h1>{TVL ? (`$${TVL}`) : "0%"}</h1>
                                        <AiFillQuestionCircle />
                                    </div>
                                </div>
                                <div className='hidden md:block'>
                                    <h1>Multiplier</h1>
                                    <div className='flex items-center space-x-1'>
                                        <h1>50x</h1>
                                        <AiFillQuestionCircle />
                                    </div>
                                </div>

                                <div onClick={() => { toggleSlider() }} className='text-2xl cursor-pointer'>
                                    {isOpenSlider ? <IoIosArrowDropdownCircle /> : <IoIosArrowDropupCircle />}


                                </div>

                            </div>

                            {isOpenSlider && (
                                <div className='flex flex-col md:flex-row justify-between md:space-x-7 space-y-4 md:space-y-0 bg-black min-h-[40vh] rounded-md text-white p-4'>
                                    <div className='md:flex hidden flex-col space-y-3 w-full pt-8 font-semibold'>
                                        <div className='flex cursor-pointer   items-center space-x-1'>
                                            <h1>Get ZEDA-ZETA LP</h1>
                                            <div className='hover:scale-110'>
                                                <FaExternalLinkAlt />
                                            </div>

                                        </div>
                                        <div className='flex cursor-pointer items-center space-x-1'>
                                            <h1>View Contract</h1>
                                            <FaExternalLinkAlt />
                                        </div>
                                        <div className='flex cursor-pointer  items-center space-x-1'>
                                            <h1>How to Farm</h1>
                                            <FaExternalLinkAlt />
                                        </div>
                                    </div>
                                    <div className='bg-[#131010] w-full font-semibold text-xl flex md:space-x-8 px-6 pt-8 flex-col md:flex-row'>
                                        <div className='mx-auto flex mb-3 space-x-3'>
                                            <h1>ZEDA Earned:</h1>
                                            <h1>0</h1>
                                        </div>
                                        <button className='p-2 rounded-sm bg-[#0fa] px-8 text-black cursor-pointer font-semibold hover:bg-[#131010] border border-[#0fa] duration-300 hover:text-white hover:border hover:border-[#0fa] h-fit w-full'>Harvest</button>


                                    </div>
                                    <div className='w-full pt-8 bg-[#131010] px-6'>
                                        <h1 className='text-2xl pb-3'>Enable Farming</h1>
                                        <button className='p-2 rounded-sm bg-[#0fa] px-8 text-black cursor-pointer font-semibold w-full md:w-fit hover:bg-[#131010] border border-[#0fa] duration-300 hover:text-white hover:border hover:border-[#0fa]'>Farm</button>
                                    </div>

                                    <div className='md:hidden mt-6 px-4 flex flex-col space-y-2'>
                                        <div className='flex justify-between'>
                                            <h1>APR</h1>
                                            <div className='flex items-center space-x-2'>
                                                <h1 className='brightness-50'>300.4%</h1>
                                                <div className='text-[#0fa]'><AiOutlineCalculator /></div>

                                            </div>
                                        </div>
                                        <div className='flex justify-between'>
                                            <h1>Multiplier</h1>
                                            <div className='flex items-center space-x-2'>
                                                <h1 className='brightness-50'>50x</h1>
                                                <div className='text-[#0fa]'><AiFillQuestionCircle /></div>

                                            </div>
                                        </div>
                                        <div className='flex justify-between'>
                                            <h1>Liquidity</h1>
                                            <div className='flex items-center space-x-2'>
                                                <h1 className='brightness-50'>$0</h1>
                                                <div className='text-[#0fa]'><AiOutlineCalculator /></div>

                                            </div>
                                        </div>
                                        <div className='flex justify-between'>
                                            <h1>Get ZEDA-ZETA LP</h1>
                                            <div className='flex items-center space-x-2'>

                                                <div className='text-[#0fa]'><FaExternalLinkAlt /></div>

                                            </div>
                                        </div>
                                        <div className='flex justify-between'>
                                            <h1>View Contract</h1>
                                            <div className='flex items-center space-x-2'>

                                                <div className='text-[#0fa]'><FaExternalLinkAlt /></div>

                                            </div>
                                        </div>
                                        <div className='flex justify-between'>
                                            <h1>How to Farms</h1>
                                            <div className='flex items-center space-x-2'>

                                                <div className='text-[#0fa]'><FaExternalLinkAlt /></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </section>
                    </main> */}

          <ZedaFarm />
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default Farm;
