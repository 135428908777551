import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { PiSwapBold } from "react-icons/pi";
import { FaPiggyBank } from "react-icons/fa";
import { GiFarmTractor } from "react-icons/gi";
import { HiRocketLaunch } from "react-icons/hi2";
import { HiDocumentCheck } from "react-icons/hi2";
import { SiTwitter } from "react-icons/si";
import { FaTelegram } from "react-icons/fa";
import logo from "../images/zetatransparent.png";
import dotlite from "../images/logo.jpg";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";

export const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  function toggleNav() {
    //console.log("clicked")
    if (isNavOpen) {
      setIsNavOpen(false);
      //console.log(isNavOpen)
    }
    if (!isNavOpen) {
      setIsNavOpen(true);
      //console.log(isNavOpen)
    }
  }

  return (
    <nav className='fixed w-full top-0 z-50'>
      <div className='flex justify-between items-center p-5 bg-[#131010] text-white md:px-10'>
        <Link to='/' className='flex items-center relative'>
          <img src={dotlite} alt='' width={40} />
          <h1 className='text-yellow-500 font-bold absolute left-7 bottom-1'>ANG</h1>
        </Link>
        <div className='md:flex space-x-10 font-semibold hidden'>
          {/* <a href='http://app.bangswap.xyz/#/swap'>
            <span className='hover:text-yellow-600 hover:scale-110 duration-300 hover:underline cursor-pointer'>
              BangSwap
            </span>
          </a> */}
          {/* <a href='http://app.bangswap.xyz/#/pool'>
            <span className='hover:text-yellow-600 hover:scale-110 duration-300 hover:underline cursor-pointer'>
              BangPool
            </span>
          </a> */}
          
          <Link to='/'>
            <span className='hover:text-yellow-600 hover:scale-110 duration-300 hover:underline cursor-pointer'>
              Farm
            </span>
          </Link>
          <Link to='/'>
            <span className='hover:text-yellow-600 hover:scale-110 duration-300 hover:underline cursor-pointer'>
              Leveraged Yield Farm
            </span>
          </Link>
          <a href='https://t.me/BangSwap'>
            <span className='hover:text-yellow-600 hover:scale-110 duration-300 hover:underline cursor-pointer'>
              Community
            </span>
          </a>
          <a href='https://bangswap.gitbook.io/bangswap/'>
            <span className='hover:text-yellow-600 hover:scale-110 duration-300 hover:underline cursor-pointer'>
              Docs
            </span>
          </a>
          <Link to='/'>
            <span className='hover:text-yellow-300 hover:scale-110 duration-300 hover:underline cursor-pointer hover:brightness-110 bg-purple-500 p-3 rounded-2xl text-black'>
              SpacePad
            </span>
          </Link>
        </div>
        {isNavOpen ? <i onClick={toggleNav} className='md:hidden block text-3xl cursor-pointer text-yellow-600 hover:scale-110 duration-300'>
          <IoCloseSharp />
        </i> : <i onClick={toggleNav} className='md:hidden block text-3xl cursor-pointer text-yellow-600 hover:scale-110 duration-300'>
          <IoMenu />
        </i>}



      </div>
      {isNavOpen && <div className='z-50 md:w-[300px] w-[105vw] min-h-screen md:min-h-min top-18 -right-4 bg-black  rounded-sm absolute text-xl font-semibold text-yellow-600 p-5'>
        <div className='flex flex-col space-y-3'>
          {/* <a href='http://app.bangswap.xyz/#/swap'>
            <div className=' flex items-center justify-end  rounded-lg p-4 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Swap</div>
          </a>
          <a href='http://app.bangswap.xyz/#/pool'>
            <div className=' flex items-center justify-end rounded-lg p-4 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>BangPool</div>
          </a>

          <Link to='/'>
            <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>BangFarm</div>
          </Link> */}
          <Link to='/'>
            <div className=' flex items-center justify-end rounded-lg p-2   cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>SpacePad</div>
          </Link>
          <Link to='/'>
            <div className=' flex items-center justify-end rounded-lg p-2   cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Farm</div>
          </Link>
          <Link to='/'>
            <div className=' flex items-center justify-end rounded-lg p-2   cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Leveraged Yield Farm</div>
          </Link>
          <a href='https://bangswap.gitbook.io/bangswap/'>
            <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Docs</div>
          </a>
          <a href='https://x.com/BangSwap?t=USPesum_Oqs3dB7zRA7yVw&s=08'>
            <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Twitter</div>
          </a>
          <a href='https://t.me/BangSwap'>
            <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Telegram</div>
          </a>
        </div>
      </div>}
    </nav>

  )
}
