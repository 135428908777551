import React, { useState, useEffect } from 'react';
import Logo from '../images/zetatransparent.png';
import icon2 from '../images/zetatransparent.png';
import icon3 from '../images/zetatransparent.png';
import icon4 from '../images/zetatransparent.png';
// import TwitterIcon from '../images/icons8-twitter.svg';
// import TelegramIcon from '../images/icons8-telegram-app.svg';
// import DiscordIcon from '../images/icons8-discord-144.svg';
//import AltNav from '../components/AltNav';
import Zetalogo from '../images/ethlogopng.png';
import Banglogo from '../images/logo.jpg';
import { FaArrowDown } from 'react-icons/fa';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { darkTheme } from '@rainbow-me/rainbowkit';
import Logo2 from '../images/zetatranspa.png'
import dotlit from "../images/logo.jpg";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from 'react-router-dom'





import { ethers } from 'ethers';

import { ICOAddress, ICO_ABI } from '../contracts/launchpad';


import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    zora,
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { Navbar } from '../components/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Blast = {
    id: 168587773,
    name: 'Blast',
    network: 'Blast',
    iconUrl: 'https://example.com/icon.svg',
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://sepolia.blast.io'] },
        default: { http: ['https://sepolia.blast.io'] },
    },
    blockExplorers: {
        default: { name: 'BlastScan', url: 'https://testnet.blastscan.io' },
        etherscan: { name: 'BlastScan', url: 'https://testnet.blastscan.io' },
    },
    contracts: {
        multicall3: {
            address: '0xca11bde05977b3631167028862be2a173976ca11',
            blockCreated: 11_907_934,
        },
    },
    testnet: false,
};

const { chains, publicClient } = configureChains(
    [Blast],
    [
        alchemyProvider({ apiKey: "1dpC3qajlAeVoj0Nwlq89b0Sms8rs8Ph" }),
        publicProvider()
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'Blast',
    projectId: '603311adc327b626d686cf35c93a2ac8',
    chains
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})


const Launchpad = () => {
    const idoArray = [
        {
            name: 'Xeleron',
            date: 'May 22, 2023',
            fundraisingAmount: 100000,
            image: icon2,
        },
        {
            name: 'XYZ Token',
            date: 'July 10, 2023',
            fundraisingAmount: 50000,
            image: icon3,
        },
        {
            name: 'ABC Coin',
            date: 'August 5, 2023',
            fundraisingAmount: 75000,
            image: icon4,
        },
    ];

    const [idoData, setIdoData] = useState(idoArray);
    //   const { signer, staticSigner } = useSelector((state) => state.web3);
    //   const dispatch = useDispatch();
    //   const { isConnected } = useAccount();
    //const [ICO_Contract, setICO_Contract] = useState(null);
    const [price, setPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [showMinPrice, setShowMinPrice] = useState(false);
    //   const { openConnectModal } = useConnectModal();


    function checkIsConnected() {
        if (!isConnected) {
            alert("Please Connect Your Wallet")
        }
    }


    useEffect(() => {
        (async () => {

            const price = "30000000000000000";

            const minPrice = "50000000000000000";

            const priceInETH = ethers.utils.formatEther(price);
            const minPriceInETH = ethers.utils.formatEther(minPrice);
            setPrice(priceInETH);
            setMinPrice(minPriceInETH);
        })();
    }, []);







    const [value, setValue] = useState({
        amountIn: '',
        amountOut: '',
    });
    const current = 'launchpad';

    function handleValueChange(e) {
        let { value, name } = e.target;
        const oppositeName = name.includes('amountIn') ? 'amountOut' : 'amountIn';
        const valueInNumber = Number(value);
        if (valueInNumber <= 0)
            return setValue({ [name]: value, [oppositeName]: '' });
        const conversionRate = 1 / price;

        if (name === 'amountIn') {
            const v2 = Number(conversionRate * valueInNumber).toFixed(8);
            setValue({ amountIn: valueInNumber, amountOut: v2 });
        } else if (name === 'amountOut') {
            const v2 = valueInNumber * price;
            setValue({ amountIn: v2, amountOut: valueInNumber });
        }
    }

    const [ICOContract, setICOContract] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const [isNavOpen, setIsNavOpen] = useState(false)


    function toggleNav() {
        //console.log("clicked")
        if (isNavOpen) {
            setIsNavOpen(false)
            //console.log(isNavOpen)
        }
        if (!isNavOpen) {
            setIsNavOpen(true)
            //console.log(isNavOpen)
        }
    }


    useEffect(() => {
        const checkIfWalletIsConnected = async () => {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const accounts = await provider.listAccounts();

                    if (accounts.length > 0) {
                        setIsConnected(true);
                        setupEthereumContract(provider);
                    } else {
                        setIsConnected(false);
                    }
                } catch (error) {
                    console.error("Error checking connected accounts:", error);
                }
            } else {
                console.log('Ethereum object not found, install MetaMask.');
                setIsConnected(false);
                // Optionally, set up a read-only provider here if needed
            }
        };

        const setupEthereumContract = (provider) => {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(ICOAddress, ICO_ABI, signer);
            setICOContract(contract);
        };

        checkIfWalletIsConnected();

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts.length > 0) {
                    setIsConnected(true);
                    setupEthereumContract(new ethers.providers.Web3Provider(window.ethereum));
                } else {
                    setIsConnected(false);
                    setICOContract(null);
                }
            });
        }
    }, []);




    //console.log(isConnected)


    //console.log(ICOContract)


    const transactionSuccessful = (amount) => toast(`Minimum contribution is 0.05 ETH`);
    const transactionFailed = () => toast("Nah! That didn't quite through!");

    async function handleContribution() {
        console.log("contribute...")


        if (value.amountIn < minPrice) {
            transactionSuccessful(value.amountIn.toString())
            console.log("contribution succeed")
        } else {
            console.log("Sufficient amount. Processing...")
        }


        if (!ICOContract) return console.log('Please Connect Wallet');
        if (value.amountIn < minPrice) {
            setShowMinPrice(true);
            return console.log(`Minimum Contribution is ${minPrice} ETH`);
        }

        const tx = await ICOContract.depositPool("2", "0", {
            value: ethers.utils.parseEther(value.amountIn.toString()),
            gasLimit: 250000,
        },);
        await tx.wait();

        //console.log(tx.hash)

        


        //displayLauchpadModal();
    }

    //   const [modal, setModal] = useState(false);
    //   function displayLauchpadModal() {
    //     setModal(true);


    //   }
    const [IDoBalance, setIDoBalance] = useState(0);



    return (





        useEffect(() => {
            // Function to log ICOContract balance
            const logICOContractBalance = async () => {
                if (ICOContract) {



                    console.log("ICOContract Address:", ICOAddress);
                    try {
                        const pprovider = ICOContract.provider;
                        const contractAddress = ICOContract.address;
                        const balance = await pprovider.getBalance(contractAddress);
                        console.log("ICOContract ETH Balance:", ethers.utils.formatEther(balance));

                        setIDoBalance(ethers.utils.formatEther(balance));
                    } catch (error) {
                        console.error("Error getting ICOContract balance:", error);
                    }
                }
            };

            // Log ICOContract balance on component mount
            logICOContractBalance();

            // Cleanup function (unsubscribe if needed)
            return () => {
                // Cleanup code (if any)
            };
        }, [ICOContract]),

        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider theme={darkTheme({
                accentColor: '#d4c528',
                accentColorForeground: '#131010',
            })} chains={chains}>
                <div className='bg-gradient-to-r from-pink-600  to-purple-600 pb-10 min-h-screen'>
                    <ToastContainer />
                    <nav className='flex items-center justify-between md:px-32 px-4 py-3 bg-black'>
                        <Link to='/' className='flex items-center relative'>
                            <img src={dotlit} alt='' width={40} />
                            <h1 className='text-yellow-500 font-bold absolute left-7 bottom-1'>ANG</h1>
                        </Link>
                        <div className='flex items-center space-x-6'>
                            <ConnectButton />
                            <div>
                                <div className='relative'>
                                    <div className='text-yellow-600 text-3xl font-bold cursor-pointer hover:brightness-75 hover:scale-125 duration-300' onClick={() => { toggleNav() }}>
                                        <IoMenu />
                                    </div>
                                    {isNavOpen && <div className=' md:w-[300px] w-[105vw] min-h-screen md:min-h-min top-[42px] z-50 -right-4 bg-black  rounded-sm absolute text-xl font-semibold text-yellow-600 p-5'>
                                        <div className='flex flex-col space-y-3'>
                                            
                                            <a href='http://app.bangswap.xyz/#/swap'>
                                                <div className=' flex items-center justify-end  rounded-lg p-4 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Swap </div>
                                            </a>
                                            <a href='http://app.bangswap.xyz/#/pool'>
                                                <div className=' flex items-center justify-end rounded-lg p-4 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Pool </div>
                                            </a>

                                            <Link to='/'>
                                                <div className=' flex items-center justify-end rounded-lg p-2   cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>SpacePad </div>
                                            </Link>
                                            <Link to='/'>
                                                <div className=' flex items-center justify-end rounded-lg p-2   cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>  Farm </div>
                                            </Link>
                                            <Link to='/'>
                                                <div className=' flex items-center justify-end rounded-lg p-2   cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Leveraged Yield Farm </div>
                                            </Link>
                                            <a href='https://bangswap.gitbook.io/bangswap/'>
                                                <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Docs </div>
                                            </a>
                                            <a href='https://t.me/BangSwap'>
                                                <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Telegram</div>
                                            </a>
                                            <a href='https://x.com/BangSwap?t=USPesum_Oqs3dB7zRA7yVw&s=08'>
                                                <div className=' flex items-center justify-end rounded-lg p-2 cursor-pointer  hover:text-[#131010] hover:border-[#131010] duration-300 text-right'>Twitter </div>
                                            </a>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>

                    </nav>
                    <div className="text-white w-[80vw] mx-auto">

                        <div className="pt-[50px]">
                            <div className="launchpad--container md:px-20 flex flex-col justify-center items-center md:pt-[40px] pt-[15px]">



                                {/*  */}

                                <div className="presale mb-8">
                                    <div>
                                        {/* <Countdown /> */}

                                    </div>

                                    {/* Presale Container */}

                                    <div className="presale--proper md:p-10 p-4 rounded-lg flex flex-col  justify-center items-center relative">
                                        <div className="flex flex-col  w-full">
                                            <div className="basis-1/2 flex flex-col justify-center items-center green-border p-5 mb-7 rounded-xl w-[350px] mx-auto bg-black">
                                                <div>
                                                    <h1 className=" text-center font-bold md:text-4xl text-2xl text-yellow-300 mb-6 bg-black p-2 rounded-2xl">
                                                        BangSwap Presale
                                                    </h1>

                                                </div>

                                                <div className="md:mb-6 mb-3">
                                                    <div className="mb-10">
                                                        <div>
                                                            <h1 className="font-semibold mb-1 text-white md:text-xl">
                                                                ETH
                                                            </h1>
                                                        </div>
                                                        <div className="flex space-x-28 items-center relative">
                                                            <input
                                                                className="p-3 w-64 border-gray-950 border-2 rounded-lg text-black font-semibold outline-none"
                                                                type="number"
                                                                id="amountIn"
                                                                placeholder="ETH Amount"
                                                                name="amountIn"
                                                                onChange={handleValueChange}
                                                                value={value.amountIn}
                                                            />
                                                            <img
                                                                src={Zetalogo}
                                                                className="bg-[#0fa] rounded-full w-6 absolute md:left-24 left-24"
                                                                alt="tokenicon "
                                                            />
                                                        </div>
                                                    </div>

                                                    <FaArrowDown className="md:mb-6 mb-6 text-yellow-600  rounded-full mx-auto" />
                                                    <div>
                                                        <div>
                                                            <h1 className="font-semibold mb-1 text-white md:text-xl">
                                                                BANG
                                                            </h1>
                                                        </div>
                                                        <div className="flex space-x-28 items-center relative mb-3">
                                                            <input
                                                                className="p-3 rounded-lg w-64 border-gray-950 border-2 text-black outline-none font-semibold"
                                                                type="number"
                                                                id="amountIn"
                                                                placeholder="BANG Amount"
                                                                name="amountOut"
                                                                onChange={handleValueChange}
                                                                value={value.amountOut}
                                                            />
                                                            <img
                                                                src={Banglogo}
                                                                className="bg-black rounded-full w-6 absolute md:left-24 left-24"
                                                                alt="tokenicon"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* {showMinPrice && (
                                                        <p className="text-red-400 font-mono">⚡️ Minimum Price: {minPrice} ⚡️</p>
                                                    )} */}


                                                    <br />
                                                    {IDoBalance > 0 &&
                                                        <p className="text-green-400"> ETH Raised: {IDoBalance}</p>}
                                                </div>
                                                <button
                                                    type="button"
                                                    className=" md:p-3 p-2  rounded-lg font-semibold text-xl
                                                    duration-300 bg-yellow-300 text-black w-64 mt-2  mb-4 cursor-pointer hover:brightness-90"
                                                    onClick={
                                                        isConnected ? handleContribution : checkIsConnected
                                                    }
                                                    disabled={!value.amountIn || !value.amountOut}
                                                >
                                                    {isConnected ? 'SWAP' : 'CONNECT WALLET'}
                                                </button>
                                            </div>

                                            <div className="md:basis-1/2 px-4 info--container text-lg">
                                                {/* <h1 className="font-bold md:text-4xl text-2xl text-white text-center mb-6">
                    OFFERING DETAILS
                  </h1> */}

                                                {/* <div className="mb-4">
                                                    <h1 className="font-bold text-xl mb-2">FROM</h1>
                                                    <h1>
                                                        <span className="text-[#0fa] font-semibold">
                                                            Date:{' '}
                                                        </span>
                                                        2nd February, 2024
                                                    </h1>
                                                    <h1>
                                                        <span className="text-[#0fa] font-semibold">
                                                            Time:{' '}
                                                        </span>
                                                        4:00pm UTC
                                                    </h1>
                                                </div> */}

                                                <div>
                                                    <h1 className="font-bold text-xl mb-2"><span className='text-yellow-500'>Sale live till:</span> 31st February, 2024 - 4:00pm UTC</h1>
                                                </div>

                                                <div>
                                                    {/* <h1>
                      <span className="text-purple-300 font-semibold">
                        Token CA:{' 0x81aF88307672aD64591afa09631Acb8fd85c5c58 '}
                      </span>
                    </h1> */}
                                                    {/* <h1>
                      <span className="text-purple-300 font-semibold">
                        Offering CA:{' 0xBB05954E31886fD8B673a36C08AB9791068a857E '}
                      </span>{' '}
                    </h1> */}
                                                    <h1>
                                                        {' '}
                                                        <span className="text-yellow-500 font-semibold">
                                                            Total BANG Token:
                                                        </span>{' '}
                                                        {500000} BANG
                                                        <span className="text-yellow-500 font-semibold">
                                                            {" "} Price:{' '}
                                                        </span>
                                                        1 ETH = {Math.floor(1 / price)} $BANG
                                                    </h1>

                                                    {/* <h1>
                      <span className="text-purple-300 font-semibold">
                        Price:{' '}
                      </span>
                      1  = {price} ZETA
                    </h1> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-sm text-center mt-20">© 2024 BangSwap || All rights reserved.</p>
                </div>
            </RainbowKitProvider>
        </WagmiConfig>

    );
};

export default Launchpad;
