import React, { useRef, useState, useEffect } from 'react';

const SlideInDiv = ({ children, className }) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      {
        threshold: 0.2, // Trigger when 10% of the div is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  return (
    <div ref={ref} className={`${className} ${isVisible ? 'slide-in' : ''}`}>
      {children}
    </div>
  );
};

function SlideInSection() {
  return (
    <section className="md:mx-28 mx-8 flex md:flex-row text-xs flex-col md:space-y-0 space-y-5 justify-center md:space-x-6 ">
      <SlideInDiv className="border-4 border-yellow-500 text-white rounded-2xl p-3">
        <h1 className="font-bold"><span className="text-yellow-500">Team:</span> BangSwap</h1>
      </SlideInDiv>
      <SlideInDiv className="border-4 border-yellow-500 text-white rounded-2xl p-3">
        <h1 className="font-bold"><span className="text-yellow-500">Category:</span> Dex + Leveraged Farming</h1>
      </SlideInDiv>
      <SlideInDiv className="border-4 border-yellow-500 text-white rounded-2xl p-3">
        <h1 className="font-bold"><span className="text-yellow-500">Status:</span> Live on MAINNET</h1>
      </SlideInDiv>
      <SlideInDiv className="border-4 border-yellow-500 text-white rounded-2xl p-3">
        <h1 className="font-bold"><span className="text-yellow-500">Potential:</span> Exponential</h1>
      </SlideInDiv>
    </section>
  );
}

export default SlideInSection;
