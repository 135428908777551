import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import logo from "../images/zetatransparent.png";
import dotlit from "../images/dotlit.gif";
import DexScreen from "../images/DEX-Screener.jpg";
import swap from "../images/swap.png";
import farm from "../images/farm.png";
import pad from "../images/pad.png";
import deff from "../images/deff.png";
import hero from "../images/hero.png";
import { CgMenuGridO } from "react-icons/cg";
import { Link } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import defiIllama from "../images/deff.png";
import dexScreener from "../images/DEX-Screener.jpg";
import { TheFarm } from "../components/TheFarm";
import p5 from 'p5';
import { FaTwitter, FaTelegram, FaDiscord } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import SlideInSection from "../components/SlideInSec";




const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const chartRef = useRef(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);









  function toggleNav() {
    //console.log("clicked")
    if (isNavOpen) {
      setIsNavOpen(false);
      //console.log(isNavOpen)
    }
    if (!isNavOpen) {
      setIsNavOpen(true);
      //console.log(isNavOpen)
    }
  }

  return (
    <div className=" pb-6 bg-gradient-to-r from-pink-600  to-purple-600 ">
      <Navbar />

      <section className="md:h-full mt-20 mb-10 flex md:flex-row flex-col-reverse md:space-x-10 md:text-400 md:px-32 items-center px-8 md:pt-28 ">
        <div className="font-bold md:basis-1/2">
          <h1 className="md:text-[60px] text-center md:text-left text-[40px] leading-tight mb-6 text-yellow-500">Blast into BangSwap</h1>
          <p className="md:text-[20px] text-[15px] text-center font-semibold mb-6 text-white md:text-left">Experience explosive liquidity, <span className="text-yellow-500">thruster-powered</span> swaps for fast trades, and <span className="text-yellow-500">leveraged-farming</span> with astronomical APRs only on BangSwap.
          </p>
          <div className="flex md:flex-row flex-col space-y-7 md:space-y-0   md:space-x-10 text-black font-medium">
            <a href="http://app.bangswap.xyz/#/swap">
              <button className="bg-yellow-500 p-4 w-full md:w-fit  rounded-lg cursor-pointer hover:brightness-125 duration-300">Open BangSwap</button>
            </a>
            <a href="https://t.me/BangSwap" className="relative">
            <h1 className="absolute -top-[15px] right-2 bg-red-500 p-2 text-xs rounded-2xl border border-yellow-300 text-yellow-300 z-40">100x</h1>
              <button className="bg-yellow-500 p-4 w-full md:w-fit rounded-lg cursor-pointer hover:brightness-125 duration-300">Leveraged Yield Farm</button>
            </a>


          </div>
        </div>
        <div className="mb-4 relative">
          <img src={hero} alt="" className="md:scale-90 scale-75" />
          <img src={hero} alt="" className="absolute md:scale-50 md:-top-[130px] md:-right-[240px] opacity-65 scale-[30%] -top-[70px] -right-[114px]"/>
        </div>
        <div>


        </div>
      </section>

      <SlideInSection />

      {/* <section className="md:px-40 px-10 md:flex md:justify-around flex">
        <div className="flex mx-auto space-x-8 items-center ">
          <img src={dexScreener} alt="" className="md:w-40 h-14" />
          <img src={deff} alt="" className="md:w-40  md:h-14 h-8" />
        </div>
      </section>

      <section className="md:pb-20 pb-8 mx-5 mt-10 rounded-3xl bg-slate-900 ">
        <div>
          <h1 className="text-white py-4  font-semibold text-center mt-14 text-[28px] md:text-[60px]">Our Products</h1>
        </div>
        <div className="text-white md:grid flex flex-col space-y-8 px-4 md:grid-cols-2 md:px-10 md:mt-10 md:gap-10 min-h-[80%]">
          <div className="md:row-span-3 bg-gradient-to-r from-red-600 to-yellow-600 rounded-3xl md:p-4 min-h-[90%] flex flex-col items-center md:space-y-4 pb-6">
            <img src={swap} alt="" className="w-[250px]" />
            <h1 className="font-bold text-3xl text-yellow-200 mb-3">SWAP</h1>
            <p className="md:px-10 px-4 text-center md:text-left text-black">The Sweetest Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchain Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchainThe Sweetest Protocol The Sweetest Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchain Protocol Builder <span className="hidden md:block">on the blockchainThe Sweetest Protocol Builder on the blockchainThe Sweetest ProtocolThe Sweetest Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchain Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchainThe Sweetest ProtocolThe Sweetest Protocol Builder on the blockchainThe
            </span>  </p>
            <a href="http://app.bangswap.xyz/#/swap">
              <button className="bg-yellow-500 md:p-4 py-2 px-8 rounded-lg cursor-pointer hover:brightness-125 duration-300 mt-6">Swap Fast</button>
            </a>
          </div>
          <div className="flex md:flex-row flex-col-reverse bg-gradient-to-r text-center from-yellow-600 to-red-600 rounded-3xl p-4 min-h-[90%] ">
            <div className="basis-1/2 flex flex-col space-y-4">
              <h1 className="font-bold text-3xl text-yellow-200">FARM</h1>
              <p className="text-black">The Sweetest Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchain Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchainThe Sweetest Protocol  </p>
              <a href="http://bangswap.xyz">
                <button className="bg-yellow-500 md:p-4 py-2 px-8 rounded-lg cursor-pointer hover:brightness-125 duration-300">Farm and Earn</button>
              </a>
            </div>
            <img src={farm} alt="" className="w-80" />
          </div>
          <div className="flex md:flex-row flex-col-reverse text-center bg-gradient-to-r from-yellow-600 to-red-600 rounded-3xl p-4 min-h-[90%] ">
            <div className="basis-1/2 flex flex-col space-y-4">
              <h1 className="font-bold text-3xl text-yellow-200">LAUNCHPAD</h1>
              <p className="text-black">The Sweetest Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchain Protocol Builder on the blockchainThe Sweetest Protocol Builder on the blockchainThe Sweetest Protocol  </p>
              <Link to="/launchpad">
                <button className="bg-yellow-500 md:p-4 py-2 px-8 rounded-lg cursor-pointer hover:brightness-125 duration-300">Use LaunchPad</button>
              </Link>
            </div>
            <img src={pad} alt="" className="w-80" />
          </div>
        </div>

        <div className="bg-gradient-to-r from-red-600 to-yellow-600 rounded-3xl p-4 mt-10 mx-2 md:mx-10 py-6">
          <h1 className="font-bold text-3xl text-yellow-200">IDEAS?</h1>
          <p className="text-white my-3 text-xs md:text-base">Do you have any idea you would love to see BangSwap implemnet? You can talk to us.</p>

          <a href="goo" className="text-slate-600 underline text-sm hover:text-yellow-600 cursor-pointer">Talk to Us</a>

        </div>

      </section>

      <section className="md:pb-20 pb-5 md:mx-10 md:px-10 px-5 mt-10 md:pt-10 pt-5 rounded-3xl bg-slate-900 flex md:flex-row flex-col space-y-8 md:space-x-20">
        <div className="bg-gradient-to-r from-red-600 to-yellow-600 rounded-3xl p-4   py-6 pt-10 basis-1/2">
          <h1 className="font-bold md:text-[40px] text-yellow-300">Join our Fast Growing Community</h1>
          <p className="text-xs">Become a member of our fast growing community and be the first to get notified of upcoming activities such as airdrops, bug bounty, AMA's, community campaign, partnerships etc.</p>
        </div>
        <div className="flex flex-col space-y-3">
          <div className="flex items-center border rounded-xl text-white p-5 space-x-8">
            <i className="md:text-[30px]">
              <FaTwitter />
            </i>

            <a href="https://x.com/BangSwap?t=USPesum_Oqs3dB7zRA7yVw&s=08" className="hover:text-yellow-600">
              <div>
                <h1 className="md:text-3xl font-bold mb-3" >Twitter</h1>
                <p className="text-xs md:text-base">Stay Up to date with BangSwap, engage us on twitter.</p>
              </div>
            </a>

          </div>


          <div className="flex items-center border rounded-xl text-white p-5 space-x-8">
            <i className="md:text-[30px]">
              <FaTelegram />
            </i>

            <a href="https://x.com/BangSwap?t=USPesum_Oqs3dB7zRA7yVw&s=08" className="hover:text-yellow-600">
              <div>
                <h1 className="md:text-3xl font-bold mb-3" >Telegram</h1>
                <p className="text-xs md:text-base">Become a part iof the strong and evergrowing community of BangSwap users</p>
              </div>
            </a>


          </div>
          <div className="flex items-center border rounded-xl text-white p-5 space-x-8">
            <i className="md:text-[30px]">
              <FaDiscord />
            </i>

            <div>
              <h1 className="md:text-3xl font-bold mb-3" >Discord</h1>
              <p className="text-xs md:text-base">Reach out to us on technical issues and become an integral part of BangSwap community</p>
            </div>
          </div>
        </div>

      </section> */}


      <p className="text-sm text-center mt-20">© 2024 BangSwap || All rights reserved.</p>


    </div>
  );
};

export default Home;
