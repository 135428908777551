import React, { useState } from "react";
import Logo2 from "../images/zetatranspa.png";
import Logo1 from "../images/ZetaChain.jpg";
import { AiOutlineCalculator } from "react-icons/ai";
import { AiFillQuestionCircle } from "react-icons/ai";
import { SiUblockorigin } from "react-icons/si";
import { FaExternalLinkAlt } from "react-icons/fa";
import ZedaChefABI from "../contracts/ZedaChef.abi.json";
import WZETA_ABI from "../contracts/WETH.abi.json";
import ZEDA_ABI from "../contracts/Zeda.abi.json";
import ROUTER_ABI from "../contracts/Router.abi.json"
//import { IoMdCloseCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import "@rainbow-me/rainbowkit/styles.css";
import { darkTheme } from "@rainbow-me/rainbowkit";
import { ethers } from "ethers";
import { LP_ABI } from "../contracts/launchpad";
import Web3Modal from "web3modal";
import { IoRefreshCircleSharp } from "react-icons/io5";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, useAccount, WagmiConfig } from "wagmi";
import { bscTestnet } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Zeta = {
  id: 7000,
  name: "Zetachain",
  network: "Zetachain",
  iconUrl: "https://example.com/icon.svg",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "ZETA",
    symbol: "ZETA",
  },
  rpcUrls: {
    public: {
      http: ["https://zetachain-evm.blockpi.network/v1/rpc/public"],
    },
    default: {
      http: ["https://zetachain-evm.blockpi.network/v1/rpc/public"],
    },
  },
  blockExplorers: {
    default: { name: "ZetaExplorer", url: "https://explorer.zetachain.io" },
    etherscan: { name: "ZetaExplorer", url: "https://explorer.zetachain.io" },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 11_907_934,
    },
  },
  testnet: false,
};
const { chains, publicClient } = configureChains(
  [Zeta],
  [
    alchemyProvider({ apiKey: "qVf7pnl78NlUKvDQ4P2mFYmfigvA4D5h" }),
    publicProvider(),
  ]
);
const { connectors } = getDefaultWallets({
  appName: "zedafarm",
  projectId: "579e29718b5b76adb4b3aade0ce1648b",
  chains,
});
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const ZedaFarm = () => {
  const [isOpenSlider, setIsOpenSlider] = useState(true);
  const [userReward, setUserReward] = useState(0);
  const [farmRewardRate, setFarmRewardRate] = useState(0);
  const { address, isConnected } = useAccount();

  const [TVL, setTVL] = useState(0);
  const [userLpBalance, setUserLpBalance] = useState("0");
  const [web3Signer, setWeb3Signer] = useState();
  const [zedaContract, setZedaContract] = useState();
  const [lpContract, setLpContract] = useState();
  const [APY, setAPY] = useState(0);
  const [ZETAPrice, setZETAPrice] = useState(0);
  const [chefContract, setChefContract] = useState("")
  const [lpValueInZeta, setlpValueInZeta] = useState(0)
  const [routerContract, setRouterContract] = useState('')
  const [lpTokenPrice, setLPTokenPrice] = useState(0)
  const [stakeInput, setStakeInput] = useState(0)
  const [unstakeValue, setUnstakeValue] = useState(0)
  const [userPoolBalance, setUserPoolBalance] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false) // for stake button

  const LP_ADDRESS = "0x0276d2322CB1bFEF7A6a2176c15ba2CF051AB5dC";
  const WETH_ADDRESS = "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf"; //wethh
  const ZEDA_ADDRESS = "0xF97956624654B7436B1B2d5993C46B84dECCacef" //zeda
  const ChefAddress = "0xa8414D2b7eB71005eBb3d91aa5Ab0790926a6B8B" //Chef
  const ROUTER_ADDRESS = "0xB377769689f81B5c82Be44A75C55BB50337C11A9" // Router


  const stakeSuccessful = (amount) => toast(`Successfully staked ${amount}  ZETA`);
  const stakeFailed = () => toast("Nah! That didn't quite through!");
  const harvestSuccessful = (amount) => toast(`Successfully harvested ${amount}  ZETA`);
  const harvestFailed = () => toast("Nah! That didn't quite go through!");
  const withdrawFailed = () => toast("Nah! That didn't quite go through!");
  const withdrawSuccessful = () => toast("Successfully exited the farm.");
  

  function processNumber(number) {
    // Check if the number is a floating point number by comparing it to its integer part
    if (number !== Math.floor(number)) {
      // Convert number to string and check if it has more than 6 decimal places
      const decimalPlaces = number.toString().split('.')[1];
      if (decimalPlaces && decimalPlaces.length > 6) {
        // Return the number rounded to 6 decimal places
        return Number(number.toFixed(6));
      } else {
        // Return the floating number as is if it has 6 or less decimal places
        return number;
      }
    } else {
      // Return the number as is if it's not a floating number
      return number;
    }
  }






  async function getDetails() {
    try {
      console.log("trying to get get Native in USD");

      const url = "https://api.coingecko.com/api/v3/simple/price";
      const params = {
        ids: 'zetachain',
        vs_currencies: 'usd'
      }
      try {
        const response = await fetch(`${url}?${new URLSearchParams(params)}`);
        const data = await response.json();


        var zetaToUsd = data.zetachain.usd;

        //console.log({ zetaprice });

        zetaToUsd ? setZETAPrice(zetaToUsd) : setZETAPrice(2500);
        console.log(`The current price of ETH is $${zetaToUsd} USD.`);
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    } catch (err) {
      console.log("Unable to get native in USD:", err)
    }

    if (!isConnected) {
      return;
    }
    const web3Modal = new Web3Modal();
    const providerWeb3 = await web3Modal.connect();

    const web3Provider = new ethers.providers.Web3Provider(providerWeb3);
    const signerWeb3 = web3Provider.getSigner();
    setWeb3Signer(signerWeb3);

    const zedaContract = new ethers.Contract(
      ZEDA_ADDRESS,
      ZEDA_ABI,
      signerWeb3
    );
    setZedaContract(zedaContract);
    console.log({ zedaContract });
    const lpContract = new ethers.Contract(
      LP_ADDRESS,
      LP_ABI,
      signerWeb3
    );
    setLpContract(lpContract);
    console.log({ lpContract });

    const wethContract = new ethers.Contract(
      WETH_ADDRESS,
      WZETA_ABI,
      signerWeb3
    )
    console.log({ wethContract });

    const chefContract = new ethers.Contract(
      ChefAddress,
      ZedaChefABI,
      signerWeb3
    )
    setChefContract(chefContract)
    console.log({ chefContract })

    const routerContract = new ethers.Contract(
      ROUTER_ADDRESS,
      ROUTER_ABI,
      signerWeb3
    )
    setRouterContract(routerContract)
    console.log({ routerContract })

    try {
      console.log("trying to get user balance of LP:")
      const userBalance = await lpContract.balanceOf(address);


      const userBalanceFormat = ethers.utils.formatEther(userBalance);
      console.log("User has", userBalanceFormat, "LP tokens");
      setUserLpBalance(processNumber(Number(userBalanceFormat)));
    } catch (err) {
      console.log("Error getting user balance of LP:", err)
    }


    // This how many LP is locked in the farm
    const chefLPBalance = await lpContract.balanceOf(
      ChefAddress
    );
    const chefLPBalance_format = ethers.utils.formatEther(chefLPBalance);
    console.log("There is", processNumber(Number(chefLPBalance_format)), "LP Tokens Locked in the pool");
    setTVL(processNumber(Number(chefLPBalance_format)));


    const tx = await chefContract.pendingTokens("0", address);
    const [amount] = tx.at(-1);
    const parsedAmount = ethers.utils.formatEther(amount);
    setUserReward(processNumber(Number(parsedAmount)));
    console.log("user pending reward in pool is:", parsedAmount)


    function calculateAPR(rewardRatePerSecond, priceOfRewardToken, totalStakedLPTokens, lpTokenValueUSD) {

      console.log("Calculating APR...", "TVL:", TVL, "rewardRatePerSecond:", rewardRatePerSecond, "priceOfRewardToken:", priceOfRewardToken, "totalStakedLPTokens:", totalStakedLPTokens, "lpTokenValueUSD:", Number(lpTokenValueUSD).toString());
      // Constants
      const secondsInYear = 365 * 24 * 60 * 60; // Total seconds in a year
      // Calculate the total value locked (TVL) in USD without rounding prematurely
      const tvlUSD = totalStakedLPTokens * lpTokenValueUSD;

      console.log("TVL in USD is:", tvlUSD);
      // Calculate the total annual rewards in USD without rounding prematurely
      const totalAnnualRewardsUSD = rewardRatePerSecond * secondsInYear * priceOfRewardToken;
      // Calculate APR using precise values
      const apr = (totalAnnualRewardsUSD / tvlUSD) * 100;
      console.log("Calc APR is:", apr);
      setAPY(processNumber(Number(apr)))
      // Return the APR as a number to maintain precision in subsequent calculations
      return apr;
    }


    async function getUserStakeInPool(pid, address) {
      console.log("getting user stake")
      const tx = await chefContract.userInfo(pid, address)
      const amountInBN = tx[0];
      const amount = ethers.utils.formatEther(amountInBN)
      console.log({ amount })
      setUserPoolBalance(processNumber(amount))
    }
    getUserStakeInPool("0", address)


    const rewardRateTX = await chefContract.getZedaPerSec();
    const rewardRate = Number(ethers.utils.formatEther(rewardRateTX));
    console.log("The reward rate per second is:", rewardRate);

    function calculateTokenPriceFromReserves(reserveOfTokenA, reserveOfTokenB, decimalsOfTokenA, decimalsOfTokenB) {
      // Adjust reserves for token decimals
      const adjustedReserveOfTokenA = reserveOfTokenA / Math.pow(10, decimalsOfTokenA);
      const adjustedReserveOfTokenB = reserveOfTokenB / Math.pow(10, decimalsOfTokenB);

      // Calculate the price of Token A in terms of Token B
      const priceOfTokenA = adjustedReserveOfTokenB / adjustedReserveOfTokenA;

      console.log("The price of Token A in terms of Token B is:", priceOfTokenA);
      return priceOfTokenA;
    }


    function calculateLPTokenValueInUSD(valueOfAssetAInUSD, valueOfAssetBInUSD, totalSupplyOfLPTokens) {
      // Calculate the total value of the pool in USD
      const totalPoolValueInUSD = valueOfAssetAInUSD + valueOfAssetBInUSD;

      // Calculate the value of one LP token in USD
      const valueOfOneLPTokenInUSD = totalPoolValueInUSD / totalSupplyOfLPTokens;

      return valueOfOneLPTokenInUSD;
    }

    const LPBalanceofWETHTx = await wethContract.balanceOf(LP_ADDRESS);
    const LPBalanceofWETH = ethers.utils.formatEther(LPBalanceofWETHTx);
    console.log("LP Balance of WETH is:", LPBalanceofWETH);
    const LPBalanceofZEDATx = await zedaContract.balanceOf(LP_ADDRESS);
    const LPBalanceofZEDA = ethers.utils.formatEther(LPBalanceofZEDATx);
    console.log("LP Balance of ZEDA is:", LPBalanceofZEDA);
    const ZEDAPriceInZETA = calculateTokenPriceFromReserves(LPBalanceofZEDA, LPBalanceofWETH, 18, 18);
    console.log("ZEDA Price in ZETA is:", ZEDAPriceInZETA);
    const LPTotalSupply = await lpContract.totalSupply();
    const LPTotalSupplyFormat = ethers.utils.formatEther(LPTotalSupply);
    console.log("Total Supply of LP tokens is:", LPTotalSupplyFormat);


    const ZEDAPriceInUSD = ZEDAPriceInZETA * zetaToUsd


    const lpTokenValueUSD = calculateLPTokenValueInUSD(ZEDAPriceInZETA, zetaToUsd, LPTotalSupply)

    console.log("Value of one LP token in USD:", lpTokenValueUSD.toFixed(2));



    const tokenPriceFromReserves = calculateTokenPriceFromReserves(239286, 10500, 18, 18)



    calculateAPR(rewardRate, tokenPriceFromReserves, TVL, lpTokenValueUSD);



    function getPoolApr(
      stakingTokenPrice,
      rewardTokenPrice,
      totalStaked,
      tokenPerBlock,
    ) {

      console.log("Calculating APR...as Pancake")
      const totalRewardPricePerYear = rewardTokenPrice * (tokenPerBlock * 31536000);
      const totalStakingTokenInPool = stakingTokenPrice * totalStaked;
      const ifPoolZero = totalStakingTokenInPool === 0 ? 1 : totalStakingTokenInPool;
      const apr = (totalRewardPricePerYear / ifPoolZero) * 150;

      console.log("Pancake APR is:", apr);
      return processNumber(apr)
    }

    setAPY(getPoolApr(ZEDAPriceInZETA, ZEDAPriceInZETA, TVL, rewardRate))
    console.log("End")
  }


  function updateInputValue(e) {
    setStakeInput(e.target.value)
    console.log(stakeInput)
  }
  function updateOutputValue(e) {
    setUnstakeValue(e.target.value)
    console.log(unstakeValue)
  }

  function onUseMax() {
    setStakeInput(userLpBalance)
  }

  function onUseMaxOutput() {
    getDetails()
    console.log("unstakeing", userPoolBalance)
    setUnstakeValue(userPoolBalance)
  }





  useEffect(() => {
    getDetails().then();
  }, [userLpBalance, userReward, TVL]);

  function toggleSlider() {
    //console.log("clicked")
    if (isOpenSlider) {
      setIsOpenSlider(false);
      //console.log(isOpenSlider)
    }
    if (!isOpenSlider) {
      setIsOpenSlider(true);
      //console.log(isOpenSlider)
    }
  }

  // Staking
  async function stakeTokens(pid, amount) {
    console.log({ amount, userLpBalance });
    console.log("calling stake tokens with amount:", stakeInput);
    // Check if a signer is available
    if (!web3Signer) return;

    const ally = await lpContract.allowance(
      ChefAddress,
      ChefAddress
    );

    const allowance = ethers.utils.formatEther(ally);
    console.log({ allowance, amount });


    //allowance ? getButtonState(true) : getButtonState(false)

    //allowance ? setIsProcessing(false) : setIsProcessing(true);

    const parsedAmount = ethers.utils.parseEther(amount);


    if (Number(allowance) <= Number(amount)) {

      await lpContract.approve(
        ChefAddress,
        parsedAmount
      );

    }
    const tx = await chefContract.deposit(pid, parsedAmount);

    const receipt = await tx.wait();
    const status = receipt.status;

    status ? stakeSuccessful(amount) : stakeFailed();

    console.log({ tx });
    await getDetails();
  }

  async function harvestReward(pid, amount) {
    // Check if a signer is available
    console.log("Unstaking amount:", amount)
    if (!web3Signer) return;
    const parsedAmount = ethers.utils.parseEther(amount);
    const tx = await chefContract.withdraw(pid, parsedAmount);

    const receipt = await tx.wait();
    const status = receipt.status;

    console.log({ status })
    status ? harvestSuccessful(amount) : harvestFailed();

    await getDetails();
  }


  function getButtonState(approved){
    if (approved){
      return "Stake"
    } else {
      return "Approve"
    }
  }

  async function emergencyWithdraw(pid) {
    console.log("trying to get out of the farm")
    const tx = await chefContract.emergencyWithdraw(pid)

    const receipt = await tx.wait();
    const status = receipt.status;

    console.log({ status })
    status ? withdrawSuccessful() : withdrawFailed();

    await getDetails();

  }









  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        theme={darkTheme({
          accentColor: "#0fa",
          accentColorForeground: "#131010",
        })}
        chains={chains}
      >
        <div className="bg-[#131010] min-h-[100vh] pb-10">
          <ToastContainer />


          <main className='text-white bg-black pt-8 h-screen'>
            <div className='w-[80%] mx-auto'>
              <section className='mb-8'>
                
                <div className='flex gap-2'>
                  <div className='flex w-[32%] justify-between items-center bg-[#131010] rounded-md px-3 py-2'>
                    <div>
                      <h4 className=''>Total Value Locked</h4>
                      <p className="text-center md:text-start mt-2 md:mt-0">{TVL ? TVL : 0}</p>
                    </div>

                  </div>
                  <div className='flex w-[32%] justify-between items-center bg-[#131010] rounded-md px-3 py-2'>
                    <div>
                      <h4 className=''>Your Holdings</h4>
                      <p className="text-center md:text-start mt-2 md:mt-0">{userLpBalance ? userLpBalance : 0}</p>
                    </div>

                  </div>
                  <div className='flex w-[32%] justify-between items-center bg-[#131010] rounded-md px-3 py-2'>
                    <div>
                      <h4 className=''>Rewards</h4>
                      <p className="text-center md:text-start mt-2 md:mt-0">{userReward ? userReward : 0}</p>
                    </div>

                    <div className="cursor-pointer hover:scale-125 duration-300 text-[#0fa]" onClick={getDetails}>
                      <IoRefreshCircleSharp />
                    </div>
                  </div>
                </div>
              </section>

              <section className='bg-[#131010] py-7 rounded-md'>
                <div className='flex align-center justify-between px-6 font-bold mb-3 text-[#0fa]'>
                  <p>Featured</p>
                  <p>Liquidity</p>
                  <p>APR</p>
                  <p>Earnings</p>
                </div>
                <div className='flex align-center justify-between px-6 font-bold'>
                  <p>ZEDA/ZETA</p>
                  <p>{TVL ? TVL : 0}</p>
                  <p>{APY ? APY : 0}</p>
                  <p>{userReward ? userReward : 0}</p>
                </div>

                <hr className='my-6'></hr>

                <div className='flex items-center flex-col md:flex-row px-6 justify-between'>
                  <div className='md:w-[32%] w-full mb-4 md:mb-0'>
                    <div className='flex justify-between mb-3'>
                      <div>Your LP Balance</div>
                      <div>{userLpBalance ? userLpBalance : 0}</div>
                    </div>

                    <div className='flex justify-between mb-3 items-center rounded-md relative h-14'>
                      <input type='text' placeholder='0.0' className='bg-slate-700 w-full h-full rounded-md px-4' value={stakeInput} onChange={updateInputValue} />
                      <button className='bg-[#0fa] px-2 py-1 rounded-md absolute right-4 top-1/2 -translate-y-1/2 text-black' onClick={onUseMax}>MAX</button>
                    </div>
                    <button className='bg-[#0fa] w-full rounded-md p-3 hover:bg-slate-800 cursor-pointer duration-300 text-black' onClick={() => stakeTokens("0", Number(stakeInput).toString())}>

                      <h1>{isProcessing ? "Processing" : "Approve" }</h1>
                      {/* {isProcessing ? "Processing" : false ?  "Stake" : 'Approve'} */}
                    </button>
                  </div>
                  <div className='w-full md:w-[32%]'>
                    <div className='flex justify-between mb-3'>
                      <div>Staked</div>
                      <div>{userPoolBalance ? userPoolBalance : 0}</div>
                    </div>

                    <div className='flex justify-between mb-3 items-center rounded-md relative h-14'>
                      <input type='text' placeholder='0.0' className='bg-slate-700 w-full h-full rounded-md px-4' value={unstakeValue} onChange={updateOutputValue} />
                      <button className='bg-[#0fa] px-2 py-1 rounded-md absolute right-4 top-1/2 -translate-y-1/2 text-black' onClick={onUseMaxOutput}>MAX</button>
                    </div>
                    <button className='bg-[#0fa] w-full rounded-md p-3 hover:bg-slate-800 cursor-pointer duration-300 text-black' onClick={() => harvestReward("0", Number(userPoolBalance).toString())}>Harvest</button>
                  </div> 

                  <div className='w-full md:w-[32%]'>

                    <div className='flex md:justify-start justify-center space-x-6 items-center'>
                      <div className='flex items-center space-x-1'>
                        <img src={Logo2} alt='' className='w-14 h-14 rounded-full' />
                        <h1>ZEDA</h1>
                      </div>
                      <h1>{userReward ? userReward : 0}</h1>
                    </div>
                    <button className='bg-[#0fa] w-full md:w-[70%] rounded-md p-3 hover:bg-slate-800 cursor-pointer duration-300 text-black' onClick={()=>emergencyWithdraw("0")}>Unstake</button>

                  </div>
                </div>

              </section>
            </div>
          </main>











        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default ZedaFarm;
